function App() {
  return (
    <div className="w-screen h-screen flex flex-col justify-between items-center">
      <header className="flex w-full justify-end">
        <nav className="">
          <a href="https://aiwan.run">ABOUT</a>
        </nav>
      </header>
      <main>qwebnm.top</main>
      <footer>
        <a href="https://beian.miit.gov.cn/">
          <span>备案号：鄂ICP备19030152号-1</span>
        </a>
      </footer>
    </div>
  );
}

export default App;
